import React, { useState } from 'react';
import { Box, Text, Button, HStack } from '@chakra-ui/react';
import locale from '../locale/locale.json';

function RateEventComponent({ userData, language, onOptionSelected }) {
    const [selectedOption, setSelectedOption] = useState(null);
    const options = [locale.severe[language], locale.minor[language]];

    const handleOptionClick = (option, index) => {
        setSelectedOption(option);
        onOptionSelected(option, index);
    };

    return (
        <Box>
            <Text>{`${locale.rate_event[language]} ${userData.name}?`}</Text>
            <HStack spacing={2} mt={2}>
                {options.map((option, index) => (
                    <Button
                        key={option}
                        bg="#248A3E"
                        size="sm"
                        color="white"
                        isDisabled={!!selectedOption}
                        onClick={() => handleOptionClick(option, index)}
                    >
                        {option}
                    </Button>
                ))}
            </HStack>
        </Box>
    );
}

export default RateEventComponent;
