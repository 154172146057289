import React, { useState } from 'react';
import {
    ChakraProvider,
    Box,
    VStack,
    Text,
    Button,
    extendTheme,
    HStack,
    Image
} from '@chakra-ui/react';
import locale from '../locale/locale.json';
import logo from './logoRIP_4.png';
import Language from '../components/Languages';

/* const data = {
    "age": "51",
    "gender": "Hombre",
    "diseases": [
        "reflujo",
        "dolor de muelas"
    ],
    "hasAlergy": true,
    "alergies": [
        "polen",
        "penicilina"
    ],
    "onMedication": true,
    "medications": [
        "aspirina",
        "vitámina b"
    ],
    "bloodType": "O+",
    "hasInsurance": true,
    "insurances": [
        "axa",
        "metlife"
    ],
    "hasIntlCoverage": false,
    "hasHospital": [
        "el faro"
    ],
    "emergencyContactName": "Sara Chavez",
    "emergencyContactPhone": "+525516778552",
    "name": "Sebastian Ortega"
}; */

const ripGreen = "#248A3E";

const theme = extendTheme({
    components: {
        Input: {
            defaultProps: {
                focusBorderColor: ripGreen,
            },
        },
        Button: {
            baseStyle: {
                _focus: {
                    boxShadow: 'none',
                },
            },
        },
    },
});

function PatientVitals() {

    const [language, setLanguage] = useState('es');
    //const [isLoading, setIsLoading] = useState(true);
    //const [error, setError] = useState('');

    const handleChangeLanguage = async (lang) => {
        setLanguage(lang);
        //setIsLoading(true);


        //console.log(body);
        if (lang === "es") {
            //setIsLoading(false);
            return;
        }
        /* try {
            //console.log("userData", userData);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/utils/translate-json`,
                { data: body });
            updateUserData(userData, response.data);
            console.log("response", response.data);
            setIsLoading(false);
        } catch (err) {
            console.error(err);
            setError('Failed to load data.');
            setIsLoading(false);
        } */

    };

    //style={{ fontFamily: 'VietnamPro', color: '#248A3E' }}
    return (

        <ChakraProvider theme={theme}>

            <Box
                bg="gray.100"
                pt={3}
                pb={0}
                px={4}
                minH="100vh"
                w={{ base: "100%", md: "375px" }} // Ajusta a 375px en pantallas md y más grandes
                mx={{ md: "auto" }} // Centra el Box horizontalmente en pantallas md y más grandes
                display="flex"
                flexDirection="column"
                overflow="hidden"
            >
                <VStack
                    spacing={4}
                    overflowY="auto"
                    flex="1"
                    p={2}
                    style={{ maxHeight: '85vh' }}
                >
                    <Image
                        src={logo}
                        alt="Logo"
                        mx="auto"
                        mb='0'
                    />
                    <HStack mt='0'>{locale.chose_language.map((lang, index) => (<Text key={index}>{lang}</Text>))}
                        <Language language={language} setLanguage={handleChangeLanguage} /></HStack>






                </VStack>

                <HStack mt={4}>

                    <Button bg={ripGreen} color='white' >
                        {locale.send[language]}
                    </Button>
                </HStack>
            </Box>
        </ChakraProvider>
    );
}


export default PatientVitals;