import React from 'react';
import { Text } from '@chakra-ui/react';
import locale from '../locale/locale.json';

function GreetComponent({ userData, language }) {
    return (
        <Text fontSize="2xl" color="black">
            {locale.greet[language]}{' '}
            <Text as="span" fontWeight="bold">
                {userData.name}
            </Text>
        </Text>
    );
}

export default GreetComponent;
