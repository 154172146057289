import React, { useState } from 'react';
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
//import { ChevronDownIcon } from '@chakra-ui/icons';


const languages = [
    { code: "es", name: "Español", flag: "🇲🇽" },
    { code: "en", name: "English", flag: "🇺🇸" },
    { code: "pt", name: "Português", flag: "🇧🇷" },
    { code: "fr", name: "Français", flag: "🇫🇷" },
    { code: "de", name: "Deutsch", flag: "🇩🇪" },
    { code: "it", name: "Italiano", flag: "🇮🇹" },
    { code: "ru", name: "Русский", flag: "🇷🇺" },
    { code: "ja", name: "日本語", flag: "🇯🇵" },
    { code: "zh", name: "中文", flag: "🇨🇳" },
    { code: "nl", name: "Nederlands", flag: "🇳🇱" },
    { code: "pl", name: "Polski", flag: "🇵🇱" }
];

const Language = ({ language, setLanguage }) => {

    const [selectedLanguage, setSelectedLanguage] = useState(language);

    const handleLanguageChange = (newLanguage) => {
        setSelectedLanguage(newLanguage);
        setLanguage(newLanguage);
    };

    return (
        <Menu>
            <MenuButton fontSize='6xl' as={Button} width='100px' height="100px">
                {languages.find(lang => lang.code === selectedLanguage).flag}
            </MenuButton>
            <MenuList>
                {languages.map(lang => (
                    <MenuItem key={lang.code} onClick={() => handleLanguageChange(lang.code)}>
                        {lang.flag} {lang.name}
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    );
}

export default Language;