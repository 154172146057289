import React from 'react';
import { ChakraProvider, Box, Heading, Text, Container, Image } from '@chakra-ui/react';
import Logo from '../assets/splash.png';

const PrivacyPolicy = () => {
    return (
        <Container maxW="container.md" py={10}>
            <Heading as="h1" mb={6}>Políticas de Privacidad de RIP ALIVE</Heading>
            <Text mb={4}>
                En RIP ALIVE, nos comprometemos a proteger su privacidad. Esta política de privacidad describe cómo recopilamos, usamos y protegemos su información personal.
            </Text>
            <Heading as="h2" size="md" mt={6} mb={4}>Información que Recopilamos</Heading>
            <Text mb={2}>
                Solo obtenemos sus contactos del teléfono si usted lo solicita, incluyendo nombre, apellido, teléfono y correo electrónico.
            </Text>
            <Text mb={2}>
                También utilizamos permisos para imágenes porque generamos un QR que es tratado como imagen, pero no obtenemos ninguna fotografía o imagen del usuario. El permiso se requiere únicamente para que el usuario pueda guardar el QR en sus fotos.
            </Text>
            <Heading as="h2" size="md" mt={6} mb={4}>Uso de la Información</Heading>
            <Text mb={2}>
                Los datos personales se obtienen con el propósito de entregar estos datos a quien usted designe. Solo obtenemos los datos que usted escribe, no requerimos ni obtenemos ningún otro dato.
            </Text>
            <Text mb={2}>
                Todos los datos se utilizan única y exclusivamente para entregarlos al beneficiario que usted designe, en las condiciones que usted designe. No transferimos datos a ninguna empresa, no vendemos sus datos ni los utilizamos para ningún otro propósito.
            </Text>
            <Heading as="h2" size="md" mt={6} mb={4}>Seguridad de la Información</Heading>
            <Text mb={2}>
                Todos los datos se transfieren de forma segura utilizando las mejores prácticas de seguridad y se almacenan en servidores seguros de Azure que son auditados para fines de seguridad.
            </Text>
            <Heading as="h2" size="md" mt={6} mb={4}>Cláusulas Estándar</Heading>
            <Text mb={2}>
                Nos reservamos el derecho de actualizar esta política de privacidad según sea necesario. Cualquier cambio será notificado a través de nuestro sitio web.
            </Text>
            <Text mb={2}>
                Si tiene alguna pregunta o inquietud sobre nuestra política de privacidad, no dude en ponerse en contacto con nosotros.
            </Text>
        </Container>
    );
};

const App = () => {
    return (
        <ChakraProvider>
            <Box textAlign="center" py={10}>
                <Image src={Logo} maxH="350px" maxW="350px" objectFit="contain" alt="Logo RIP ALIVE" mx="auto" mb={6} />
                <PrivacyPolicy />
            </Box>
        </ChakraProvider>
    );
};

export default App;
