import React, { useEffect, useState } from 'react';
import { Box, Text, Spinner, Center, Button, VStack, Image } from '@chakra-ui/react';
import { IoLogoAndroid, IoLogoApple } from 'react-icons/io';

const DownloadAPP = () => {
    const [isRedirecting, setIsRedirecting] = useState(true);

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
            // Dispositivo Android
            window.location.replace(process.env.REACT_APP_ANDROID_URL);
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            // Dispositivo iOS
            window.location.replace(process.env.REACT_APP_APPLE_URL);
        } else {
            // Otros dispositivos o desktop
            setIsRedirecting(false);
        }
    }, []);

    if (isRedirecting) {
        return (
            <Center height="100vh">
                <Box textAlign="center">
                    <Spinner size="xl" />
                    <Text mt={4}>Redirigiendo...</Text>
                </Box>
            </Center>
        );
    }

    return (
        <Center height="100vh">
            <VStack spacing={4}>
                <Image
                    src="https://ripfiles.blob.core.windows.net/public/RIPALIVE_200px.png"
                    alt="Logo"
                    boxSize="250px"
                    objectFit="contain"
                />
                <Text style={{ fontSize: '24px' }}>Descarga nuestra aplicación</Text>
                <Button
                    as="a"
                    href={process.env.REACT_APP_ANDROID_URL}
                    leftIcon={<IoLogoAndroid size={24} />}
                    colorScheme="green"
                    size="lg"
                >
                    Descargar para Android
                </Button>
                <Button
                    as="a"
                    href={process.env.REACT_APP_APPLE_URL}
                    leftIcon={<IoLogoApple size={24} />}
                    colorScheme="blue"
                    size="lg"
                >
                    Descargar para iOS
                </Button>
            </VStack>
        </Center>
    );
};

export default DownloadAPP;
