import { Box, Text, Image, Heading, OrderedList, ListItem, UnorderedList } from "@chakra-ui/react";
import Logo from '../assets/splash.png';

const TerminosCondiciones = () => {
    const ripGreen = "#248A3E";
    return (<>
        <Box textAlign="center" py={10}>
            <Image src={Logo} maxH="350px" maxW="350px" objectFit="contain" alt="Logo RIP ALIVE" mx="auto" mb={6} />
            <Heading as="h1" size="xl" mb={4} color={ripGreen}>
                Términos y Condiciones
            </Heading>
        </Box>
        <Box p={8}>

            <Text mb={4}>
                Bienvenido(a) a RIP alive. Te invitamos a leer detenidamente estos Términos y Condiciones antes de utilizar nuestra plataforma digital. Al acceder y hacer uso de las funcionalidades de RIP alive, aceptas cumplir con estos términos.
            </Text>

            <Heading as="h2" size="lg" mb={2} color={ripGreen}>
                Información del Proveedor
            </Heading>
            <Text mb={4}>
                <strong>Nombre Comercial:</strong> RIP alive. <br />
                <strong>Marca:</strong> RIP alive. <br />
                <strong>Razón social:</strong> MFROJ SOLUTIONS S.A. DE C.V. <br />
                <strong>Domicilio:</strong> LAGO MERU 50 - 303 CDMX 11520. <br />
                <strong>Registro Federal de Contribuyentes (RFC):</strong> MSO1504142H4. <br />
                <strong>Medios de contacto:</strong> Llamando al 01-800-RIP o enviando un correo electrónico a centrodeatencion@ripalive.live o contactándonos en nuestras redes sociales (IG, FB, X, y TikTok) bajo el usuario @ripaliveoficial. <br />
                <strong>Sitio web:</strong> www.ripalive.live y www.ripalive.com.
            </Text>

            <Heading as="h2" size="lg" mb={2} color={ripGreen}>
                Definiciones
            </Heading>
            <Text mb={4}>
                <strong>APP:</strong> Se refiere a la aplicación RIP alive para dispositivos móviles, accesible sin costo en App Store y Play Store. <br />
                <strong>Beneficiario:</strong> Persona designada para acceder a la información esencial y los datos de contacto críticos de un usuario de RIP alive. Los beneficiarios pueden ser designados por el usuario dentro de la etiqueta de nombre, que es una forma de identificarlos y darles permiso para acceder a su información. <br />
                <strong>Contenido:</strong> Incluye texto, imágenes, videos, gráficos y otros materiales disponibles en la app. <br />
                <strong>Plataforma digital de RIP alive:</strong> Se refiere a un entorno en línea que incluye tanto el sitio web como la aplicación móvil desarrollada y operada por MFROJ SOLUTIONS S.A. DE C.V. Esta plataforma permite a los usuarios acceder a diversas funcionalidades y servicios, como la creación de un Código QR único vinculado a un repositorio de información esencial y datos de contacto críticos. <br />
                <strong>Punto de contacto de emergencia:</strong> Persona dentro del listado de contactos que el usuario designa para recibir una notificación en caso de que su RIP ID sea escaneado en caso de algún evento crítico. <br />
                <strong>RIP ID:</strong> Código QR diseñado por RIP alive que se imprime en un porta identificación de polipapel similar a un sobre. Este código se vincula con los datos que sustentan el servicio proporcionado.<br />
                <strong>Usuario:</strong> Persona que, habiendo aceptado los presentes Términos y Condiciones, puede hacer uso de RIP alive.<br />
            </Text>

            <Heading as="h2" size="lg" mb={2} color={ripGreen}>
                Registro y Cuenta
            </Heading>
            <Text mb={4}>
                Para acceder a la totalidad de funciones de la app, es necesario registrarse y crear una cuenta. Al hacerlo, te comprometes a proporcionar información verdadera, precisa y actualizada. Es importante que tengas presente que, como Usuario, eres responsable de la confidencialidad de tus credenciales de acceso y de todas las actividades que ocurran bajo tu cuenta. Si sospechas que tu cuenta ha sido comprometida, debes notificarnos de inmediato.
            </Text>

            <Heading as="h2" size="lg" mb={2} color={ripGreen}>
                Restablecimiento de Contraseña
            </Heading>
            <Text mb={4}>
                Si olvidas tu contraseña, puedes restablecerla entrando a la pantalla de inicio en “Olvidé mi contraseña”. Luego, introduce tu correo electrónico o número de teléfono, selecciona el método de notificación (mensaje, correo electrónico o celular) y presiona "Enviar contraseña". Espera a recibir la clave, anótala y presiona "Validar clave". Finalmente, el sistema te permitirá escribir una nueva contraseña.
            </Text>

            <Heading as="h2" size="lg" mb={2} color={ripGreen}>
                Uso de la App
            </Heading>
            <Text mb={4}>
                Desde el diseño, hemos contemplado un uso legal y responsable de nuestra app, por lo que está prohibido:
                <ul>
                    <li>Utilizar la app para actividades ilegales.</li>
                    <li>Acceder o intentar acceder a cuentas de otros usuarios sin autorización.</li>
                    <li>Utilizar la app de manera que pueda dañar, desactivar, sobrecargar o deteriorar el funcionamiento de la app o interferir con el uso de la app por parte de otros usuarios.</li>
                </ul>
            </Text>

            <Heading as="h2" size="lg" mb={2} color={ripGreen}>
                Guía de uso
            </Heading>
            <Text mb={4}>
                Proporcionamos medios audiovisuales como imágenes, notas de referencia (pop-ups) y videos para ayudarte a explorar RIP alive y con ello comprender mejor nuestros productos o servicios. Esta información está disponible en nuestra app y se proporciona como referencia.
            </Text>

            <Heading as="h2" size="lg" mb={2} color={ripGreen}>
                Descripción de la app y los servicios relacionados con ésta.
            </Heading>

            <Text mb={4}>
                <strong>¿En qué consiste nuestra app?</strong>
                La app contine cinco módulos que a continuación se describen:
                <OrderedList spacing={3}>
                    <ListItem><strong>My RIP ID: </strong>Conjunto de formularios denominados "8 datos vitales" que buscan compilar la información esencial para proporcionar ayuda en caso de un incidente menor. Una vez llenados los ocho datos vitales, se generará un código QR único para cada usuario, cuyos datos estarán accesibles globalmente y se presentarán automáticamente en el idioma del lugar donde se necesite asistencia. </ListItem>
                    <ListItem><strong>My legacy: </strong>Consta de cinco secciones en las que el usuario puede detallar la información pertinente para eventos desafortunados, proporcionando acceso gratuito a su(s) beneficiario(s) para revisar dicha información. Estas secciones son:
                        <OrderedList styleType="lower-alpha" spacing={3}>
                            <ListItem>Es vital el orden en mi documentación.</ListItem>
                            <ListItem>Asesores que mi(s) beneficiario(s) debe(n) consultar o notificar.</ListItem>
                            <ListItem>Mi herencia financiera y seguros que espero se preserven.</ListItem>
                            <ListItem>Pasos por seguir después de mi partida.</ListItem>
                            <ListItem>Mis datos personales para cuando ya no esté aquí.  </ListItem>

                        </OrderedList>
                    </ListItem>
                    <ListItem><strong>Alive Calendar:   </strong>Sección en la que el usuario puede programar recordatorios especialmente diseñados en relación con su bienestar, incluyendo la administración de fármacos, la renovación de pagos de seguros y las fechas clave para actualizar documentos vitales. En este contexto, RIP alive notifica al usuario sobre servicios brindados por sus aseguradoras, animándolo a aprovechar los beneficios que su seguro le proporciona. </ListItem>
                    <ListItem><strong>RIP alive shop:  </strong>Un espacio en la aplicación donde los usuarios pueden adquirir una variedad de accesorios personalizables como soportes para identificación, fundas de pasaporte, tarjetas plásticas, dijes de metal, y pulseras de estilo aviador, todos ellos grabados con su respectivo RIP ID según la preferencia de cada cliente. </ListItem>
                    <ListItem><strong>EXPERIENCIAS RIP alive: </strong>Sección dirigida a usuarios que se han inscrito como "promotores de la cultura RIP alive" a través del menú de la aplicación con el objetivo de acumular puntos. Estos puntos se pueden canjear por experiencias seleccionadas por RIP alive", lo cual les otorga la oportunidad de disfrutar de beneficios variados y sorprendentes en cualquier momento.</ListItem>
                </OrderedList>

                <strong>¿En qué consiste nuestro servicio?</strong>

                Nuestro servicio consiste en la creación de un Código QR único (RIP ID) que se entrega en un porta identificaciones de polipapel, este código está directamente vinculado con un soporte digital (repositorio) que alberga información esencial y datos de contacto críticos correspondientes a los módulos
                <strong> My RIP ID</strong> y <strong>My legacy</strong>. Esto asegura que, en caso de alguna emergencia que afecte tu salud o seguridad, las personas que estén presentes, y tu(s) contacto(s) designado(s), sean alertados y tengan acceso a los detalles necesarios para tomar las acciones apropiadas y contactar a las personas relevantes.
                El servicio puede utilizarse en el idioma que el usuario elija después de contratar RIP alive y generar el QR incluido en RIP ID. Es accesible desde cualquier lugar con conexión a internet y a través de dispositivos capaces de escanear imágenes.
                El servicio se proporcionará durante un período de un año, con una renovación automática incluida.

            </Text>

            <Heading as="h2" size="lg" mb={2} color={ripGreen}>
                Servicios adicionales
            </Heading>
            <Text mb={4}>
                RIP ID proporciona funcionalidades adicionales correspondientes a los módulos <strong>ALIVE calendar</strong>, <strong>RIP alive shop</strong> y <strong>Experiencias RIP alive</strong> que puedes utilizar sin incurrir en costos adicionales, ¡esperamos te sean útiles y las disfrutes!
            </Text>

            <Heading as="h2" size="lg" mb={2} color={ripGreen}>
                Procedimiento para adquirir tu RIP ID
            </Heading>
            <Text mb={4}>
                Para unirte a la comunidad RIP alive y obtener tu RIP ID descarga la app en tu dispositivo móvil y seguir las instrucciones señaladas en la aplicación para crear tu cuenta, y posteriormente, proporcionando la información que se requiere en cada una de las secciones. Recuerda proporcionar sólo la información esencial para aprovechar las funcionalidades de RIP alive, evitando incluir información sensible. Habiendo concluido esta parte, generaremos tu código QR y tu RIP ID, misma que enviaremos al domicilio que hayas indicado.
                <UnorderedList>
                    <ListItem><strong>RIP ID: </strong>Es un soporte para identificaciones del tamaño estándar de una licencia de conducir o una credencial de elector, elaborado con polipapel (papel plastificado), que tiene impresa la leyenda "leer en caso de emergencia" y, en el dorso, presenta un código QR generado en la aplicación y asociado a la información sustancial de identificación del usuario.</ListItem>
                    <ListItem><strong>Importe total:  </strong>La cantidad a abonar se presenta en moneda nacional (MXN), incluyendo el coste de RIP alive y RIP ID correspondiente a cada cliente. Los costos de envío están ya incluidos en la cuota anual de RIP alive, sujeta a la tarifa actual. Dicho importe se publicará y mantendrá al día en el apartado de pagos.  </ListItem>
                </UnorderedList>
                Si eres miembro RIP alive y hay cambios futuros en las tarifas, éstas se anunciarán con un mes de anticipación.
                La aplicación también incluye un icono de "campana" que proporciona notificaciones, recordatorios y sugerencias, donde también se detallarán costos, cambios de tarifas y eventuales promociones o descuentos a discreción de RIP alive.

            </Text>

            <Heading as="h2" size="lg" mb={2} color={ripGreen}>
                Garantías, devoluciones, plazos y restricciones.
            </Heading>
            <Text mb={4}>
                Antes de enviar tu RIP ID, verificamos que ésta se encuentre en buen estado y que el código QR sea legible. Pero si el producto llega dañado o no funciona como debería, tienes 15 días naturales para reportarlo y devolverlo a RIP alive, al domicilio indicado al inicio de estos T&C. Una vez confirmando el estado del producto que estás devolviendo, te enviaremos tu nuevo RIP ID.
                Si extravías tu RIP ID, tienes la opción de obtener uno de reemplazo. El precio de este se calculará con base en el costo de los materiales necesarios para su fabricación, y el costo ya incluye los gastos de envío.
                RIP alive no repondrá ningún RIP ID si su deterioro es causado por un uso inadecuado del usuario.

            </Text>

            <Heading as="h2" size="lg" mb={2} color={ripGreen}>
                Método de Pago
            </Heading>
            <Text mb={4}>
                Nuestros productos o servicios se pagan mediante transacciones digitales usando Mercado Pago. La adhesión a los protocolos de seguridad de este sistema de pago asegura la protección y privacidad de tus datos.
                RIP alive implementa el estándar PCI DSS (Payment Card Industry Data Security Standard) en su app para garantizar la seguridad de los datos de las tarjetas de crédito de nuestros usuarios. Sin embargo; es importante aclarar que, RIP alive no almacena ni trata ningún dato bancario en sus servidores. Esta información es procesada única y exclusivamente por Mercado Pago, nuestro proveedor de servicios de pago digital, que cumple con los más altos niveles de seguridad y privacidad. De esta manera, RIP alive ofrece un entorno de pago seguro y confiable, reduciendo significativamente el riesgo de fraudes y accesos no autorizados a la información financiera de nuestros clientes.

            </Text>

            <Heading as="h2" size="lg" mb={2} color={ripGreen}>
                Método de Envío y Entrega
            </Heading>
            <Text mb={4}>
                El envío y la entrega de los productos estarán sujetos a los términos acordados al momento de la compra tal como se señala a continuación.
                <UnorderedList>
                    <ListItem><strong>Costos de envío:</strong> Los costos de envío ya están contemplados en la contratación anual de RIP alive.</ListItem>
                    <ListItem><strong>Fechas de entrega:</strong> Tras la generación de tu código QR, procederemos a la creación de tu RIP ID, y la recibirás en un periodo de 7 a 10 días hábiles.</ListItem>
                </UnorderedList>
            </Text>


            <Heading as="h2" size="lg" mb={2} color={ripGreen}>
                Procedimiento para renovar, modificar o cancelar tu cuenta.
            </Heading>
            <Text mb={4}>
                Puedes renovar, modificar o cancelar los servicios adquiridos como se señala a continuación

                <UnorderedList>
                    <ListItem><strong>Renovación: </strong>Se efectúa de manera automática y a través de pago anual recurrente.</ListItem>
                    <ListItem><strong>Modificación: </strong>Debido a que solo disponemos de un plan de servicio, no es posible efectuar cambios en ese aspecto. No obstante, dentro de la aplicación la opción “menú” permite editar información desde el principio hasta la finalización de la suscripción anual. Esto aplica únicamente para los datos ingresados previamente en la aplicación. </ListItem>
                    <ListItem><strong>Cancelación: </strong>Puedes dar de baja tu suscripción al servicio RIP alive en cualquier momento. Para solicitar la cancelación del servicio, el usuario debe seguir estos pasos: ir a la opción “perfil”, luego "mi suscripción" y finalmente "cancelar suscripción".
                        Si tu suscripción anual está próxima a expirar y prefieres evitar la renovación automática para el próximo período y el correspondiente cargo anual, debes realizar el proceso antes señalado con una antelación mínima de 5 días hábiles.
                    </ListItem>

                </UnorderedList>

            </Text>



            <Heading as="h2" size="lg" mb={2} color={ripGreen}>
                Derechos de Propiedad Intelectual
            </Heading>
            <Text mb={4}>
                Todo el contenido de la app, incluyendo, pero no limitado a textos, gráficos, logos, imágenes y software, es propiedad de RIP alive o de nuestros licenciantes, está protegido por las leyes de propiedad intelectual. No puedes copiar, modificar, distribuir ni reproducir el contenido sin nuestra autorización previa por escrito.
            </Text>

            <Heading as="h2" size="lg" mb={2} color={ripGreen}>
                Privacidad y Protección de Datos
            </Heading>
            <Text mb={4}>
                Nos tomamos muy en serio tu privacidad. La forma en que manejamos tu información personal está detallada en nuestro <strong>Aviso de Privacidad</strong>, que te recomendamos revisar para entender cómo recolectamos, utilizamos y protegemos tus datos.
            </Text>


            <Heading as="h2" size="lg" mb={2} color={ripGreen}>
                Protección de cuentas y seguridad
            </Heading>
            <Text mb={4}>
                Agradecemos la confianza que depositas en nosotros, por eso queremos que sepas que la protección de las cuentas de usuario se basa en un sistema de autenticación segura, que administra las sesiones y las contraseñas de forma eficiente y robusta. Las contraseñas se guardan de manera cifrada y solo el usuario puede restablecerlas previa verificación de su identidad.
                RIP alive se aloja en una plataforma en la nube que ofrece un alto nivel de seguridad para los datos, tanto cuando están almacenados como cuando se transmiten. Esta plataforma utiliza diversos métodos de cifrado y protocolos para proteger los datos, y permite al usuario administrar las claves de cifrado de forma segura.
                El software se ha desarrollado siguiendo las normas internacionales para la especificación de requisitos y la realización de pruebas de software, asegurando una documentación y un proceso de pruebas de calidad. Además, se han realizado pruebas de seguridad para identificar y mitigar las vulnerabilidades más comunes en las aplicaciones web, garantizando así un software seguro y confiable.
                RIP alive cumple con un estándar de seguridad para garantizar la seguridad de los datos de las tarjetas de crédito de nuestros usuarios. Este estándar establece una serie de controles y procedimientos para proteger la información de pago mediante cifrado y tokenización, ofreciendo un entorno de pago seguro y reduciendo el riesgo de fraudes y accesos no autorizados.

            </Text>


            <Heading as="h2" size="lg" mb={2} color={ripGreen}>
                Responsabilidad y exclusiones
            </Heading>
            <Text mb={4}>
                En RIP alive nos esforzamos por ofrecerte una experiencia segura y confiable, pero queremos recordarte que la información que decides compartir en la app es responsabilidad de cada usuario. Te sugerimos que solo ingreses los datos básicos necesarios para aprovechar al máximo nuestras funciones.
                RIP alive no puede garantizar la exactitud o veracidad de la información proporcionada por sus usuarios, así como tampoco nos podemos responsabilizar del uso o manejo de información por parte de los usuarios, beneficiarios o Puntos de Contacto designados, por lo que ellos serán responsables de cualquier acción que tomen con la información a la que el usuario les dé acceso.
                Por nuestra parte, nos comprometemos a mantener tu información segura, accesible y tal como la configures al momento de ingresarla en la plataforma. Nuestra responsabilidad se limita a proteger tus datos según tus preferencias, pero no podemos controlar ni ser responsables del uso que otros hagan de ellos.
                Sabemos que a veces las cosas no funcionan como deberían, y que puede haber fallas, interrupciones o errores en la app. En caso de que esto ocurra, nos disculpamos por las molestias que esto pueda ocasionarte, y te aseguramos que estamos trabajando para solucionarlos lo antes posible. Sin embargo, no podemos prometerte un plazo específico para el restablecimiento del servicio, pero puedes tener por seguro, que haremos lo que esté en nuestros manos para solucionarlo a la brevedad.

            </Text>



            <Heading as="h2" size="lg" mb={2} color={ripGreen}>
                Normativa Aplicable
            </Heading>
            <Text mb={4}>
                Estos Términos y Condiciones se regirán e interpretarán de acuerdo con las leyes mexicanas. Cualquier disputa relacionada con estos Términos y Condiciones será sometida a la jurisdicción de los tribunales competentes de la Ciudad de México.
            </Text>



            <Heading as="h2" size="lg" mb={2} color={ripGreen}>
                Confirmación de Aceptación
            </Heading>
            <Text mb={4}>
                Al registrarte en RIP alive, y hacer uso de RIP ID confirmas que has leído, entendido y aceptas nuestros Términos y Condiciones. Esta aceptación incluye el reconocimiento de que los Términos y Condiciones pueden ser modificados ocasionalmente y que es tu responsabilidad revisar periódicamente cualquier cambio.
            </Text>

            <Heading as="h2" size="lg" mb={2} color={ripGreen}>
                Contacto
            </Heading>
            <Text mb={4}>
                Si tienes alguna duda o comentario sobre estos Términos y Condiciones, no dudes en contactarnos a través del 01-800-RIP, enviando un correo electrónico a centrodeatencion@ripalive.live, o en nuestras redes sociales (IG, FB, X y TikTok) bajo el usuario @ripaliveoficial.
            </Text>
        </Box>
    </>
    );
};

export default TerminosCondiciones;
