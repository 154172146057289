import React from 'react';
import { Center, Button, ChakraProvider, Box, Heading, Text, VStack, Image } from '@chakra-ui/react';
import { IoLogoAndroid, IoLogoApple } from 'react-icons/io';

const App = () => {
    return (
        <ChakraProvider>
            <Box textAlign="center">
                <Center >
                    <VStack spacing={4}> {/* Ajusta el espacio entre elementos */}
                        <Image
                            src="https://ripfiles.blob.core.windows.net/public/RIPALIVE_200px.png"
                            alt="Logo"
                            boxSize="200px"
                            objectFit="contain"
                        />
                        <Heading as="h1" mb={4} p={4}>Cuenta expirada y sin información vigente.</Heading>
                        <Heading as="h1" mb={8} p={4}>Ve a tu aplicación de compras y adquiérela nuevamente.</Heading>
                        <Text style={{ fontSize: '24px' }}>Descarga nuestra aplicación</Text>
                        <Button
                            as="a"
                            href={process.env.REACT_APP_APPLE_URL}
                            leftIcon={<IoLogoAndroid size={24} />}
                            colorScheme="green"
                            size="lg"
                        >
                            Descargar para Android
                        </Button>
                        <Button
                            as="a"
                            href={process.env.REACT_APP_ANDROID_URL}
                            leftIcon={<IoLogoApple size={24} />}
                            colorScheme="blue"
                            size="lg"
                        >
                            Descargar para iOS
                        </Button>
                    </VStack>
                </Center>
            </Box>
        </ChakraProvider >
    );
};

export default App;
