import { Box, Center, Image, TableContainer, Table, Thead, Tr, Tbody, Td, Th, Text, Heading, ListItem, UnorderedList, Divider } from "@chakra-ui/react";
import Logo from '../assets/splash.png';
const AvisoDePrivacidad = () => {
    const ripGreen = "#248A3E";
    return (<>
        <Box textAlign="center" py={10}>
            <Image src={Logo} maxH="350px" maxW="350px" objectFit="contain" alt="Logo RIP ALIVE" mx="auto" mb={2} />
        </Box>
        <Box p={8}>

            <Heading as="h1" size="xl" mb={8} color={ripGreen}>
                <Center>AVISO DE PRIVACIDAD INTEGRAL RIP alive</Center>
            </Heading>
            <br />

            <Heading as="h2" size="lg" mb={2} color={ripGreen} >
                Identidad y domicilio del responsable
            </Heading>
            <Text mb={4}>
                MFROJ SOLUTIONS S.A. DE C.V. (RIP alive), con domicilio para oír y recibir notificaciones ubicado en, Lago Meru # 50, Int. 303, CDMX, 11520. manifiesta ser la responsable del tratamiento de los datos personales de acuerdo con el contenido del presente Aviso de Privacidad.
                Para efectos de este Aviso de Privacidad, Titular se refiere a toda persona que genera una cuenta para explorar la Plataforma RIP ALIVE, en tanto que Usuario es aquella persona que ha decidido suscribirse a RIP alive y formar parte de nuestra comunidad.
            </Text>

            <Heading as="h2" size="lg" mb={2} color={ripGreen} >
                Datos personales
            </Heading>
            <Text mb={4}>
                Recabamos datos personales cuando el Titular descarga nuestra app y nos los proporciona a través de formularios de registro y uso.
                Los datos usados son: Datos de identificación y contacto; datos de pasatiempos, patrimoniales y financieros (estos últimos no aplican a menores).

            </Text>

            <Heading as="h2" size="lg" mb={2} color={ripGreen} >
                Datos sensibles
            </Heading>
            <Text mb={4}>
                Le informamos que, en caso de sumarse a la comunidad RIP alive, es necesario tratar datos que por sus características se consideran sensibles datos sensibles; específicamente, características físicas y de salud.
            </Text>


            <Text mb={4}>
                <strong>Nota. </strong> <br></br>Los datos personales de menores son tratados con la información proporcionada por los adultos responsables de su cuidado. La representación y el ejercicio de derechos sobre estos datos se regirá por la normativa civil aplicable.
            </Text>

            <Heading as="h2" size="lg" mb={2} color={ripGreen}>
                Finalidades del tratamiento
            </Heading>
            <Text textDecoration="underline">
                Primarias:
            </Text>
            <Text mb={4}>
                <UnorderedList>
                    <ListItem>Registrar al Titular para darle acceso a la plataforma de RIP alive.</ListItem>
                    <ListItem>Verificar la identidad de los Titulares.</ListItem>
                    <ListItem>Dar acceso a la plataforma de RIP alive.</ListItem>
                    <ListItem>Generar la RIP ID del usuario.</ListItem>
                    <ListItem>Gestionar el acceso y uso de la información del usuario RIP alive.</ListItem>
                    <ListItem>Facilitar la comunicación y el contacto entre los Titulares y RIP alive.</ListItem>
                </UnorderedList>
                <Text textDecoration="underline">
                    Secundarias:
                </Text>
                Enviar información al Titular sobre nuevas funcionalidades de RIP ID que puedan ser de su interés.
                <br />
                <br />
                En caso de que el Titular no desee que sus datos personales sean tratados las finalidades adicionales, desde este momento puede comunicárnoslo enviando un correo electrónico a la dirección centrodeatencion@ripalive.live.
                La negativa del uso de los datos personales para el cumplimiento de fines adicionales, no podrá ser un motivo para negar el acceso a RIP alive, ni el uso de RIP ID.

            </Text>



            <Heading as="h2" size="lg" mb={2} color={ripGreen}>
                Transferencias de datos personales
            </Heading>

            <Text mb={4}>
                Le informamos que, los datos que son obtenidos a través de este sitio web sólo son tratados por RIP alive y no se transfieren a terceros.
            </Text>


            <Heading as="h2" size="lg" mb={2} color={ripGreen}>
                Medios para el ejercicio de derechos ARCO y revocación del Consentimiento
            </Heading>

            <Text mb={4}>
                El Titular o su representante legal podrán ejercer cualquiera de los derechos de acceso, rectificación, cancelación u oposición, también conocidos como derechos ARCO, así como revocar su consentimiento para el tratamiento de sus datos atendiendo lo siguiente:
                Presentar la solicitud respectiva en el domicilio de nuestra Oficina de Privacidad mediante el formato que ponemos a su disposición con el siguiente contenido:
                <UnorderedList>
                    <ListItem>Nombre del solicitante, y en su caso, de su representante legal. </ListItem>
                    <ListItem>Los documentos que acrediten la identidad, o en su caso, la representación legal del Titular de los datos personales. Domicilio y/o correo electrónico para efecto de la atención de la solicitud para el ejercicio del derecho de que se trate. </ListItem>
                    <ListItem>La descripción clara y precisa del derecho que quiere ejercerse, así como los datos personales respecto de los que se busca ejercer alguno de los derechos mencionados<sup>1</sup></ListItem>
                    <ListItem>Cualquier medio o documento que facilite la ubicación o identificación de los datos personales</ListItem>
                </UnorderedList>
                Los plazos para la atención del ejercicio de derechos ARCO, así como la revocación de consentimiento son los siguientes:
            </Text>
            <br />
            <TableContainer>
                <Table variant="simple" size="md">
                    <Thead>
                        <Tr>
                            <Th border="1px solid">Acción</Th>
                            <Th border="1px solid">Plazo</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td border="1px solid">
                                Requerimiento de información adicional (en caso de que la solicitud del Titular no sea clara):
                            </Td>
                            <Td border="1px solid">5 días hábiles</Td>
                        </Tr>
                        <Tr>
                            <Td border="1px solid">Desahogo del requerimiento por parte del Titular de los datos:</Td>
                            <Td border="1px solid">10 días hábiles</Td>
                        </Tr>
                        <Tr>
                            <Td border="1px solid">Respuesta a la solicitud por parte de Nombre comercial:</Td>
                            <Td border="1px solid">20 días hábiles</Td>
                        </Tr>
                        <Tr>
                            <Td border="1px solid">Respuesta a la solicitud en caso de ampliación por parte de RIP alive:</Td>
                            <Td border="1px solid">40 días hábiles</Td>
                        </Tr>
                        <Tr>
                            <Td border="1px solid">Plazo para hacer efectivo el derecho en caso de que resulte procedente:</Td>
                            <Td border="1px solid">15 días hábiles</Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>

            <Text mb={4}>
                Los plazos indicados en el párrafo anterior podrán ser ampliados por el mismo tiempo señalado por una sola ocasión y por causa justificada.
            </Text>



            <Heading as="h2" size="lg" mb={2} color={ripGreen}>
                Opciones y medios para limitar el uso o divulgación de los datos personales
            </Heading>
            <Text mb={4}>
                El Titular podrá limitar el uso o divulgación de sus datos personales haciendo su solicitud vía correo electrónico a la dirección centrodeatencion@ripalive.live. En caso de que su solicitud sea procedente se le registrará en el listado con el que el Nombre comercial cuenta para tal efecto.
                Adicionalmente la limitación del uso y divulgación de los datos personales puede ampliarse mediante la inscripción del número telefónico de los Titulares de datos personales en el Registro Público para evitar Publicidad (REPEP) https://www.repep.profeco.gob.mx, que está a cargo de la Procuraduría Federal del Consumidor (PROFECO), con la finalidad de que sus datos personales no sean utilizados para recibir publicidad o promociones de empresas de bienes o servicios

            </Text>

            <Heading as="h2" size="lg" mb={2} color={ripGreen}>
                Uso de cookies, web beacons u otras tecnologías similares
            </Heading>
            <Text mb={4}>
                Le informamos que RIP alive no hace uso de ningún tipo de tecnología de rastreo.
            </Text>
            <Heading as="h2" size="lg" mb={2} color={ripGreen}>
                Cambios al aviso de privacidad
            </Heading>
            <Text mb={4}>
                Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente aviso de privacidad. El Titular podrá consultar dichos cambios en la sección de Privacidad de RIP alive.
            </Text>
            <Heading as="h3" size="lg" mb={2} color={ripGreen}>
                Al continuar explorando la app, o bien, al proporcionarnos sus datos, el Titular manifiesta conocer el contenido, alcance, términos y condiciones descritos en el presente Aviso de Privacidad y consiente de manera expresa el tratamiento de sus datos personales, patrimoniales y financieros conforme a los usos y finalidades señalados en el presente Aviso de Privacidad.
            </Heading>
            <Text mb={4}>
            </Text>
            <Divider borderColor="gray" borderWidth="1px" borderStyle="solid" mb={8} />


            <Text mb={4}>
                <sup>1</sup> Si se trata de un derecho de corrección, indicar los datos a corregir y la corrección a realizar, o bien, si se trata de un derecho de oposición, en su caso, indicar las finalidades secundarias sobre las que se desea ejercer el derecho. </Text>
        </Box></>
    );
};

export default AvisoDePrivacidad;
