// UserMessage.js
import React from 'react';
import { Text } from '@chakra-ui/react';

function UserMessage({ message }) {
    return (
        <Text color="white">
            {message}
        </Text>
    );
}

export default UserMessage;
